import React from "react";
import Image from './Image'
import Button from './Button'
import ClipPathLeft from "../utils/ClipPathLeft";
import ClipPathRight from "../utils/ClipPathRight";

const CTACard = ({ image, title, subtitle, buttonText, buttonUrl, summary, context }) => {
    return (
        <div className={`${context} card auto-layout auto-layout-20`}>
            {image ?
                <div className="card--image">
                    <Image source={image} clipPath="left" ratio="1-1" />
                </div>
            :
            <div className="card--image">
                <div className={`image-container ratio-1-1`} data-clip-path="left">
                    <ClipPathLeft />
                    <img className="content" src="https://res.cloudinary.com/new-territory-delta/image/upload/w_1800/Group_74_be9ee8e7c9" alt="no image  found" />
                </div>
            </div>
            }
            <div className="featured-page-card--meta card--meta background--white auto-layout auto-layout-20">
                {title ?
                    <h3>{title}</h3>
                : null}
                {subtitle ?
                <p className="grey">{subtitle}</p>
                : null}
                {summary ?
                    <p>{summary}</p>
                :null}
                {buttonText && buttonUrl ?
                    <Button 
                        type="button"
                        className="primary"
                        url={buttonUrl}
                        text={buttonText}
                    />
                :null}
            </div>
        </div>
    )
}

export default CTACard;