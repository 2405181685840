import React from "react";
import { Link } from "gatsby";
import Chevron from "../Icons/Chevron";

const Button = ({ type, className, text, icon, url }) => {
    let URL = url || '/404';
    if(url === false) {
        return (
                <button
                type={type}
                className={className}
            >
                {text}
                {icon === 'chevron' ?
                    <Chevron />
                :null}
            </button>
        )
    } else {
        return (
            <Link to={URL}>
                <button
                    type={type}
                    className={className}
                >
                    {text}
                    {icon === 'chevron' ?
                        <Chevron />
                    :null}
                </button>
            </Link>
        )
    }
}

export default Button;