import React, { useState, useEffect } from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'
import CTACard from '../components/common/CTACard'
import { Helmet } from 'react-helmet'

export const query = graphql`
  query SearchsQuery {
    allStrapiPages {
      nodes {
        Paragraph
        Slug
        Title
        HeroImage {
          provider_metadata {
            public_id
          }
        }
      }
    }
    allStrapiRisk {
      edges {
        node {
          id
          Slug
          Title
          Paragraph
          HeroImage {
            provider_metadata {
              public_id
            }
          }
        }
      }
    }
    allStrapiProducts {
      nodes {
        Slug
        Title
        Paragraph
        HeroImage {
          provider_metadata {
            public_id
          }
        }
      }
    }
    allStrapiBlogs {
      nodes {
        Summary
        Title
        Slug
        Thumbnail {
          provider_metadata {
            public_id
          }
        }
      }
    }
  }
`

const Search = ({ location, data }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [resultsCount, setResultsCount] = useState(0)
  const Pages = data.allStrapiPages.nodes
  const Products = data.allStrapiProducts.nodes
  const News = data.allStrapiBlogs.nodes
  const Risks = data.allStrapiRisk.nodes

  useEffect(() => {
    let params = new URLSearchParams(location.search)
    if (params.get('query')) {
      setSearchTerm(params.get('query').toLowerCase())
    }
  })

  return (
    <Layout>
      <Helmet>
        <title>Delta Underwriting | Search Results</title>
        <meta
          name="description"
          content="The latest news and updates from Delta Insurance"
        />
      </Helmet>
      <div className="page-container">
        <section data-section-id="news-index">
          <h1 class="h2 spacing-large m-spacing-small">
            Showing Results for "{searchTerm}"
          </h1>
          <div className="gc-3-col t-gc-2-col m-gc-1-col grid-gap-1-col">
            {Pages.map((page, i) =>
              page.Title?.toLowerCase().includes(searchTerm) ||
              page.Paragraph?.toLowerCase().includes(searchTerm) ? (
                page.Slug !== 'form-success' ? (
                  <CTACard
                    image={page.HeroImage}
                    title={page.Title}
                    summary={page.Paragraph}
                    buttonUrl={`/pages/${page.Slug}`}
                    buttonText="Learn more"
                  />
                ) : null
              ) : null
            )}
            {Products.map((product, i) =>
              product.Title?.toLowerCase().includes(searchTerm) ||
              product.Paragraph?.toLowerCase().includes(searchTerm) ? (
                <CTACard
                  image={product.HeroImage}
                  title={product.Title}
                  summary={product.Paragraph}
                  buttonUrl={`/products/${product.Slug}`}
                  buttonText="Learn more"
                />
              ) : null
            )}
            {News.map((blog, i) =>
              blog.Title?.toLowerCase().includes(searchTerm) ||
              blog.Summary?.toLowerCase().includes(searchTerm) ? (
                <CTACard
                  image={blog.Thumbnail}
                  title={blog.Title}
                  summary={blog.Summary}
                  buttonUrl={`/news/${blog.Slug}`}
                  buttonText="Learn more"
                />
              ) : null
            )}
          </div>
        </section>
      </div>
    </Layout>
  )
}

const NoResults = () => <p>Your search returned no results</p>

export default Search
